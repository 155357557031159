import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'
import { FieldConfig, LabelledFieldConfig } from '../../types'
import { getFieldLabel } from '../../utils'

function resolveLabel({
  fieldId,
  labelOverride,
  formPrefix,
}: {
  fieldId: string
  labelOverride?: string
  formPrefix?: string
}): string {
  if (labelOverride) return labelOverride

  return getFieldLabel(fieldId, formPrefix)
}

export function useLabelProps<T extends FieldValues>({
  type,
  fieldId,
  ...rest
}: LabelledFieldConfig<T> | FieldConfig<T>) {
  const repeating = 'repeating' in rest && rest.repeating
  const props = useMemo(() => {
    return {
      ...(type !== 'lookup' && type !== 'read-only' && !repeating
        ? {
            htmlFor: String(fieldId),
            labelId: undefined,
          }
        : {
            htmlFor: undefined,
            labelId: `${String(fieldId)}Label`,
          }),
      label: resolveLabel({
        fieldId,
        labelOverride: 'labelOverride' in rest ? rest.labelOverride : undefined,
        formPrefix: 'formPrefix' in rest ? rest.formPrefix : undefined,
      }),
    }
  }, [type])

  return props
}
