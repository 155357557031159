import { useLocation } from '@msaf/router-react'
import { useMemo } from 'react'
import { useTableState } from '../../../../../../../hooks/use-table-state'
import { LookupItem } from '../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../types/notes'
import { RouteMode } from '../../../../../../../types/route'
import { FieldWatchResultProps } from '../../../../../../../forms/components/hooks/use-field-watch'
import { FormElementConfig, ProposedTotals } from '../../../../../../../forms/types'
import { toDollarCurrencyFormat } from '../../../../../../../forms/utils'
import { useFencingConfig } from './fencing-config'
import { useGateTroughConfig } from './gate-trough-config'
import { usePlantingConfig } from './planting-config'
import { useSiteMaintenanceConfig } from './site-maintenance-config'
import { useSitePreparationConfig } from './site-preparation-config'
import { UploadedFile } from '../../../../../../../forms/components/file-controller'
import { usePermissionLoading } from '../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../types/permissions'

export interface ApplicationDetailsFields {
  identifier: string
  fundingType: Partial<LookupItem>
  hasAdditionalFundingTags: boolean
  additionalFundingTags: Partial<LookupItem>[]
  fundingTaggedThirdPartyName: string
  startYear: string
  endYear: string
  planDescription: string
  estimatedFreightCost: number
  plantingManagementIntentions: string
  proposedTotals: ProposedTotals

  hasCoFunders: boolean | null
  coFunders: Array<{
    name: string | null
    contribution: number | null
  }>

  hasAdditionalCosts: boolean | null
  additionalCosts: Array<{
    type: Partial<LookupItem> | null
    cost: number | null
    reason: string | null
  }>
  totalAdditionalCost: number | null

  percentageLandownerContribution: number
  percentageKmrContribution: number

  files: UploadedFile[]
  notes: Array<AppNoteType>
  status: LookupItem
  availableForReopen: boolean

  priceEffectiveDate: string
  isFonterraPlan?: boolean

  // For migrated plans only
  dateCommitted: string
  hasAlreadyBeenReviewed?: boolean
  maintenanceSprayingActions: Array<{
    type: Partial<LookupItem> | null
    area: number | null
    actualCost: number | null
  }>
  sitePreparationActions: Array<{
    type: Partial<LookupItem> | null
    area: number | null
    actualCost: number | null
  }>
  plantingActions: Array<{
    type: Partial<LookupItem> | null
    area: number | null
    cost: number | null
    numberOfPlants: number
    zoneAWetlandPercent: number | null
    zoneBLowerBankPercent: number | null
    zoneCTransitionPercent: number | null
  }>
  fencingActions: Array<{
    type: Partial<LookupItem> | null
    length: number | null
    fenceType: Partial<LookupItem>
    cost: number | null
  }>
  gateTroughActions: Array<{
    type: Partial<LookupItem> | null
    units: number | null
    cost: number | null
  }>
}

export interface ApplicationDetailsConfig {
  applicationId?: string
  planId?: string
  mode?: RouteMode
  data?: ApplicationDetailsFields
  isMigrated?: boolean
}

export const useApplicationDetailsConfig = ({
  planId,
  applicationId,
  mode,
  data,
  isMigrated,
}: ApplicationDetailsConfig): FormElementConfig<ApplicationDetailsFields>[] => {
  const url = useLocation()
  const { rowId, showModal, refreshTable, setShowModal, deleteAction, postDeleteAction } = useTableState()
  const isFonterraPlan = !!data?.isFonterraPlan
  const { hasPermission: hasFonterraEditPermissions } = usePermissionLoading([
    UserPermissions.EDIT_APPLICATION_PRICE_EFFECTIVE_DATE,
    UserPermissions.EDIT_FONTERRA_APPLICATION_DATE_COMMITTED,
  ])

  const canViewEditDetailsRestrictedFields = (isFonterraPlan && hasFonterraEditPermissions) || isMigrated

  const action: FormElementConfig<ApplicationDetailsFields>[] = useMemo(
    () =>
      !isMigrated
        ? [
            {
              type: 'action',
              element: {
                type: 'button',
                buttonStyle: 'secondary',
                label: 'Add/edit actions',
                icon: 'map',
                iconAlignment: 'left',
                path: `/plans/edit/${planId}/map?origin=${url.pathname}`,
              },
            },
          ]
        : [],
    [isMigrated],
  )

  const siteMaintenanceActions = useSiteMaintenanceConfig({
    mode,
    refreshTable,
    isMigrated,
    planId,
    applicationId,
    deleteAction,
  })

  const sitePreparationActions = useSitePreparationConfig({
    mode,
    refreshTable,
    isMigrated,
    planId,
    applicationId,
    deleteAction,
  })

  const plantingActions = usePlantingConfig({
    mode,
    refreshTable,
    isMigrated,
    planId,
    applicationId,
    data,
    deleteAction,
  })

  const fencingActions = useFencingConfig({
    mode,
    refreshTable,
    isMigrated,
    planId,
    applicationId,
    deleteAction,
  })

  const gatesTroughActions = useGateTroughConfig({
    mode,
    refreshTable,
    isMigrated,
    planId,
    applicationId,
    deleteAction,
  })

  const recordProcessing: FormElementConfig<ApplicationDetailsFields>[] = useMemo(
    () =>
      canViewEditDetailsRestrictedFields
        ? [
            {
              type: 'atom',
              element: { type: 'divider' },
            },
            {
              type: 'atom',
              element: { type: 'heading', level: 2, content: 'Record processing' },
            },
            {
              renderOnlyWhen: isFonterraPlan && hasFonterraEditPermissions,
              type: 'field',
              element: {
                type: 'date',
                fieldId: 'dateCommitted',
                labelOverride: 'Date approved',
                isRequired: isFonterraPlan,
                helpText: isFonterraPlan
                  ? 'Please enter the date the KMR co-funded FEP or remediation funding was approved.'
                  : 'Please leave this blank for KMR co-funded FEP projects with no planting or fencing actions.',
              },
            },
            {
              renderOnlyWhen: isMigrated || isFonterraPlan,
              type: 'field',
              element: {
                type: 'boolean',
                fieldId: 'hasAlreadyBeenReviewed',
                isRequired: true,
                ...(isFonterraPlan && {
                  labelOverride: 'Has the Fonterra review and approval process been followed?',
                  helpText:
                    'Please leave this blank for KMR co-funded FEP projects with no planting or fencing actions.',
                }),
              },
            },
            {
              renderOnlyWhen: isFonterraPlan && hasFonterraEditPermissions,
              type: 'field',
              element: {
                type: 'date',
                fieldId: 'priceEffectiveDate',
                isRequired: true,
                minDate: '2023-01-01',
                helpText: `This date determines the pricing schedule that is applied across the project. \
                  By default it uses the date the project was created in the system. \
                  This can be updated by a Team Lead user to apply a different schedule. \
                  If you want to use a more current price schedule, then change this field to today's date.`,
              },
            },
          ]
        : [],
    [isMigrated, isFonterraPlan],
  )

  const modal: FormElementConfig<ApplicationDetailsFields> = useMemo(
    () => ({
      type: 'modal',
      element: {
        isOpen: showModal,
        type: 'confirm-action',
        heading: 'Delete remediation action',
        body: 'Are you sure you want to delete this remediation action?',
        contentLabel: 'Remediation action delete confirmation dialog',
        successMessage: 'Remediation action deleted',
        errorMessage: 'Could not delete remediation action. Please try again.',
        primaryActionLabel: 'Delete',
        requestUrl: `/api/plan/${planId}/features/${rowId}`,
        postActionFunc: postDeleteAction,
        onRequestClose: () => setShowModal(false),
      },
    }),
    [showModal, planId, rowId, postDeleteAction, setShowModal],
  )

  return useMemo(
    () => [
      modal, // Can be anywhere in the form, shows as a modal...
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Project details' },
      },
      {
        type: 'field',
        element: {
          type: 'text',
          isDisabled: !isFonterraPlan,
          fieldId: 'identifier',
          formPrefix: 'projectDetails',
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'fundingType',
          lookupId: 'application_funding_type',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          type: 'boolean',
          fieldId: 'hasAdditionalFundingTags',
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          repeating: true,
          defaultValue: {},
          lookupId: 'application_funding_tag',
          fieldId: 'additionalFundingTags',
          allowDuplicates: false,
          isHidden: !data?.hasAdditionalFundingTags,
          watch: ({ info, initialConfig, methods, data }) => {
            if (info.type === 'change' && info.name == 'hasAdditionalFundingTags') {
              if (!data.hasAdditionalFundingTags) {
                methods.setValue('additionalFundingTags', [])
              } else {
                methods.setValue('additionalFundingTags', [{}])
              }
              return { ...initialConfig, isHidden: !data?.hasAdditionalFundingTags }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'text',
          fieldId: 'fundingTaggedThirdPartyName',
          isHidden: !data?.hasAdditionalFundingTags,
          watch: ({ info, initialConfig, data }) => {
            if (info.type === 'change' && info.name == 'hasAdditionalFundingTags') {
              return { ...initialConfig, isHidden: !data?.hasAdditionalFundingTags }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'startYear',
          showMonthYearPicker: true,
          dateFormat: 'MMM yyyy',
          isRequired: !isMigrated,
        },
      },

      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'endYear',
          showMonthYearPicker: true,
          dateFormat: 'MMM yyyy',
          isRequired: !isMigrated,
          helpText: 'This will help KMR to know if any follow up or sign off visits are due.',
        },
      },
      {
        type: 'field',
        element: {
          type: 'text-area',
          fieldId: 'planDescription',
          isRequired: !isMigrated,
          helpText: (
            <>
              This should explain the rationale for this recommended phasing of work. This information is provided back
              to applicants, so please provide sufficient context.
            </>
          ),
        },
      },
      {
        type: 'field',
        element: {
          type: 'text-area',
          fieldId: 'plantingManagementIntentions',
          helpText: 'This should be proportionate to the scale and complexity of the project.',
        },
      },
      {
        type: 'field',
        renderOnlyWhen: !data?.isFonterraPlan,
        element: {
          type: 'files',
          fieldId: 'files',
          heading:
            'Upload your word document with annotated photos, and (if relevant) your planting and/or management plan.',
        },
      },
      ...action,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      ...fencingActions,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      ...gatesTroughActions,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      ...sitePreparationActions,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      ...plantingActions,
      {
        type: 'field',
        element: {
          type: 'number',
          fieldId: 'estimatedFreightCost',
          helpText: 'Where freight is used – refer to the KMR schedule of pricing to estimate cost.',
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      ...siteMaintenanceActions,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Project management, governance, and engagement costs' },
      },
      {
        type: 'field',
        element: {
          type: 'boolean',
          fieldId: 'hasAdditionalCosts',
          helpText:
            'For landowner grants, the only additional project cost that can be co-funded is the project management \
        of large planting projects. Discuss with KMR before entering any other project cost for landowner grants.',
          watch: ({ info, initialConfig, methods, data }) => {
            if (info.type === 'change' && info.name == 'hasAdditionalCosts') {
              if (!data.hasCoFunders) {
                methods.setValue('additionalCosts', [])
              } else {
                methods.setValue('additionalCosts', [{ type: null, cost: null, reason: null }])
              }
              return initialConfig
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'repeating-section',
          heading: '',
          sectionName: 'Additional project cost',
          repeating: true,
          allowEmpty: true,
          fieldId: 'additionalCosts',
          isHidden: !data?.hasAdditionalCosts,
          watch: ({ info, initialConfig, methods, data }) => {
            if (info.type === 'change' && info.name == 'hasAdditionalCosts') {
              !data.hasAdditionalCosts
                ? methods.setValue('additionalCosts', [])
                : methods.setValue('additionalCosts', [{ type: {}, cost: null, reason: null }])

              return {
                ...initialConfig,
                isHidden: !data?.hasAdditionalCosts,
              }
            }
            return initialConfig
          },
          fields: [
            {
              type: 'lookup',
              fieldId: 'type',
              lookupId: 'additional_cost_type',
              isRequired: !isMigrated,
              labelOverride: 'Project cost type',
            },
            {
              type: 'number',
              fieldId: 'cost',
              labelOverride: 'Cost across entire project',
              min: 0,
              isRequired: !isMigrated,
            },
            {
              type: 'text-area',
              fieldId: 'reason',
              labelOverride: 'Rationale - include any estimated hours or costed rates',
              isRequired: !isMigrated,
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalAdditionalCost',
          conversion: toDollarCurrencyFormat,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Contribution breakdown' },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'proposedTotals.totalProposedCost',
          conversion: toDollarCurrencyFormat,
        },
      },
      {
        type: 'field',
        element: {
          type: 'boolean',
          fieldId: 'hasCoFunders',
          helpText: !data?.hasCoFunders
            ? ''
            : 'Please name and enter the amount from each co-funding provider. \
            Co-funding contributions are deducted first, then the remaining cost \
            is split between KMR and the landowner.',
          watch: ({ info, initialConfig, methods, data }) => {
            if (info.type === 'change' && info.name == 'hasCoFunders') {
              if (!data.hasCoFunders) {
                methods.setValue('coFunders', [])
              } else {
                methods.setValue('coFunders', [{ name: null, contribution: null }])
              }
              return {
                ...initialConfig,
                helpText: !data?.hasCoFunders
                  ? ''
                  : 'Please name and enter the amount from each co-funding provider. \
                  Co-funding contributions are deducted first, then the remaining cost \
                  is split between KMR and the landowner.',
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'repeating-section',
          heading: '',
          sectionName: 'Co-funding',
          repeating: true,
          allowEmpty: true,
          fieldId: 'coFunders',
          isHidden: !data?.hasCoFunders,
          watch: ({ info, initialConfig, data }) => {
            if (info.type === 'change' && info.name == 'hasCoFunders') {
              return {
                ...initialConfig,
                isHidden: !data?.hasCoFunders,
              }
            }
            return initialConfig
          },

          fields: [
            {
              type: 'text',
              fieldId: 'name',
              labelOverride: 'Co-funding provider',
              isRequired: !isMigrated,
            },
            {
              type: 'number',
              fieldId: 'contribution',
              labelOverride: 'Amount contributed',
              min: 0,
              isRequired: !isMigrated,
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'number',
          fieldId: 'percentageLandownerContribution',
          isRequired: !isMigrated,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationDetailsFields>) => {
            if (info.type === 'change' && info.name == 'percentageKmrContribution') {
              if (data?.percentageKmrContribution)
                methods.setValue(
                  'percentageLandownerContribution',
                  100 - Math.min(data?.percentageKmrContribution, 100),
                )
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'percentageKmrContribution',
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationDetailsFields>) => {
            if (info.type === 'change' && info.name == 'percentageLandownerContribution') {
              if (data?.percentageLandownerContribution) {
                methods.setValue(
                  'percentageKmrContribution',
                  100 - Math.min(data?.percentageLandownerContribution, 100),
                )
              } else {
                methods.setValue('percentageKmrContribution', 100)
              }
            }
            return initialConfig
          },
          helpText: 'This percentage contribution by KMR excludes any alternative forestry actions',
        },
      },
      ...recordProcessing,
      {
        type: 'atom',
        renderOnlyWhen: data?.isFonterraPlan,
        element: { type: 'divider' },
      },
      {
        type: 'field',
        renderOnlyWhen: data?.isFonterraPlan,
        element: {
          type: 'files',
          fieldId: 'files',
          heading: 'Upload Fonterra application and approval',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.APPLICATION_DETAILS,
        },
      },
    ],
    [
      modal,
      action,
      siteMaintenanceActions,
      sitePreparationActions,
      plantingActions,
      fencingActions,
      gatesTroughActions,
      recordProcessing,
      data,
    ],
  )
}
