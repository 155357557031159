import { TableAction } from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useRef } from 'react'
import { useAuth } from '../../../../../../../../auth'
import { RowClickActionType } from '../../../../../../../../components/summary-table'
import { useApplicationState } from '../../../../../../../../contexts/application-context'
import { FieldWatchResultProps } from '../../../../../../../../forms/components/hooks/use-field-watch'
import { ButtonProps, FormElementConfig, LabelledFieldConfig, TableProps } from '../../../../../../../../forms/types'
import { useMutationWithToast } from '../../../../../../../../hooks/use-mutation-with-toast'
import { useTableState } from '../../../../../../../../hooks/use-table-state'
import { usePermission } from '../../../../../../../../services/permissions'
import { LookupItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { RouteMode } from '../../../../../../../../types/route'
import { UploadedFile } from '../../../../../../../../forms/components/file-controller'

export interface ApplicationReviewFields {
  assignedMaurikuraTeamMember: LookupItem
  dateReviewed: string
  priceEffectiveDate: string | null
  maurikuraChangesRequested: boolean
  reviewCommentsFromForestryAdvisor: string | null
  summaryOfMaurikuraRequestedChanges: string
  meetsPanelReviewThreshold: boolean
  panelReviewReason: string
  notifyPanelMembers: boolean | null
  feedbackRequiredBy: string | null
  panelChangesRequested: boolean | null
  summaryOfPanelRequestedChanges: string
  considerationsForPanelMembers: string
  newFeedbackBtn?: string
  files: UploadedFile[]
  notes: AppNoteType[]
}

export interface PanelFeedbackTableFields {
  id: string
  panelist: string
  dateReviewed: string
  changesRecommended: string
  meetsPanelReviewThreshold: boolean
}

export const useApplicationReviewConfig = (
  planId?: string,
  applicationId?: string,
  mode?: RouteMode,
  data?: ApplicationReviewFields,
): FormElementConfig<ApplicationReviewFields>[] => {
  const meetsPanelReviewThreshold = useRef(!!data?.meetsPanelReviewThreshold)
  const navigate = useNavigate()
  const { user } = useAuth()
  const hasEditPermission = usePermission(UserPermissions.EDIT_REVIEW)
  const hasAssignTeamMemberOnlyPermission = usePermission([
    UserPermissions.EDIT_REVIEW,
    UserPermissions.EDIT_REVIEW_ASSIGN_TEAM_MEMBER_ONLY,
  ])
  const hasPanelFeedbackPermission = usePermission([
    UserPermissions.EDIT_REVIEW,
    UserPermissions.EDIT_REVIEW_PANEL_FEEDBACK,
  ])
  const hasEditPriceEffectiveDatePermission = usePermission([UserPermissions.EDIT_APPLICATION_PRICE_EFFECTIVE_DATE])
  // Disable all fields if the user does not have permission to edit the form
  const disableField = mode === RouteMode.EDIT && !hasEditPermission
  // Disable 'Assigned Maurikura team member' if user does not have edit permission for the form or permission to edit the field
  const disableAssignReviewer = mode === RouteMode.EDIT && !hasAssignTeamMemberOnlyPermission
  const { rowId, showModal, refreshTable, setShowModal, deleteAction, postDeleteAction } = useTableState()
  const { isMigrated } = useApplicationState()

  const createFeedback = useMutationWithToast<
    AxiosResponse<PanelFeedbackTableFields>,
    AxiosError<Array<string> | Map<keyof PanelFeedbackTableFields, Array<string>>>,
    Partial<{
      plan: string
      application: string
      meetsPanelReviewThreshold: boolean
    }>,
    void
  >({
    method: 'POST',
    url: `/api/plan/${planId}/project/${applicationId}/panelist-review/`,
    success: { toastMessage: 'Panel review created.' },
    error: { toastMessage: 'Could not create panel review.' },
  })

  const getTableActions = (meetsPanelReviewThreshold?: boolean): TableAction[] =>
    mode === RouteMode.EDIT && meetsPanelReviewThreshold
      ? [
          {
            label: 'Delete',
            type: 'deleteAction',
            args: [
              {
                elementKey: 'id',
              },
            ],
            actionPermissions: [UserPermissions.EDIT_REVIEW, UserPermissions.EDIT_REVIEW_PANEL_FEEDBACK],
          },
        ]
      : []

  const onTableRowClick: RowClickActionType = {
    label: 'View',
    type: 'transitionAction',
    args: [
      {
        constant: `plans/${
          !data?.meetsPanelReviewThreshold ? RouteMode.VIEW : mode
        }/${planId}/review/project/${applicationId}/review/panelist-review`,
      },
      { elementKey: 'id' },
    ],
  }

  return [
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'KMR Maurikura review' },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'assignedMaurikuraTeamMember',
        isRequired: true,
        lookupId: 'maurikura_team_member',
        isDisabled: disableAssignReviewer,
      },
    },
    {
      type: 'field',
      element: {
        type: 'date',
        fieldId: 'dateReviewed',
        isRequired: !disableField,
        isDisabled: disableField,
      },
    },
    {
      renderOnlyWhen: !isMigrated,
      type: 'field',
      element: {
        type: 'date',
        fieldId: 'priceEffectiveDate',
        isRequired: true,
        isDisabled: !hasEditPriceEffectiveDatePermission || disableField,
        // Prior to this date the system wasn't in prod use and migrated records don't use this field.
        minDate: '2023-01-01',
        helpText: `This date determines the pricing schedule that is applied across the project. \
          By default it uses the date the project was created in the system. \
          This can be updated by a Team Lead user to apply a different schedule. \
          If you want to use a more current price schedule, then change this field to today's date.`,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'maurikuraChangesRequested',
        type: 'boolean',
        isRequired: !disableField,
        isDisabled: disableField,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'summaryOfMaurikuraRequestedChanges',
        type: 'text-area',
        isRequired: !!data?.maurikuraChangesRequested && !disableField,
        isDisabled: !data?.maurikuraChangesRequested || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'maurikuraChangesRequested') {
            if (!data.maurikuraChangesRequested) {
              methods.setValue('summaryOfMaurikuraRequestedChanges', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'text-area',
              isRequired: !!data?.maurikuraChangesRequested && !disableField,
              isDisabled: !data?.maurikuraChangesRequested,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        type: 'files',
        fieldId: 'files',
        heading: 'Uploaded files',
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'reviewCommentsFromForestryAdvisor',
        type: 'text-area',
        isRequired: false,
        isDisabled: disableField,
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Panel review decision' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'meetsPanelReviewThreshold',
        type: 'boolean',
        isRequired: !disableField,
        helpText: 'For example, it is above $20,000, or relates to a whenua whānui grant',
        isDisabled: disableField,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'panelReviewReason',
        type: 'text-area',
        isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
        isDisabled: !data?.meetsPanelReviewThreshold || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'meetsPanelReviewThreshold') {
            meetsPanelReviewThreshold.current = !!data.meetsPanelReviewThreshold
            if (!data.meetsPanelReviewThreshold) {
              methods.setValue('panelReviewReason', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'text-area',
              isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
              isDisabled: !data?.meetsPanelReviewThreshold || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Panel feedback' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'notifyPanelMembers',
        type: 'boolean',
        isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
        isDisabled: !data?.meetsPanelReviewThreshold || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'meetsPanelReviewThreshold') {
            if (!data.meetsPanelReviewThreshold) {
              methods.setValue('notifyPanelMembers', null)
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'boolean',
              isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
              isDisabled: !data?.meetsPanelReviewThreshold || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'feedbackRequiredBy',
        type: 'date',
        isRequired: !!data?.notifyPanelMembers && !disableField,
        isDisabled: !data?.notifyPanelMembers || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (
            info.type === 'change' &&
            (info.name === 'notifyPanelMembers' || info.name === 'meetsPanelReviewThreshold')
          ) {
            // Check `notifyPanelMembers` value if either `notifyPanelMembers` value itself changes or `meetsPanelReviewThreshold` value changes
            if (!data.notifyPanelMembers) {
              methods.setValue('feedbackRequiredBy', null)
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'date',
              isRequired: !!data?.notifyPanelMembers && !disableField,
              isDisabled: !data?.notifyPanelMembers || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'considerationsForPanelMembers',
        type: 'text-area',
        isDisabled: !data?.notifyPanelMembers || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (
            info.type === 'change' &&
            (info.name === 'notifyPanelMembers' || info.name === 'meetsPanelReviewThreshold')
          ) {
            // Check `notifyPanelMembers` value if either `notifyPanelMembers` value itself changes or `meetsPanelReviewThreshold` value changes
            if (!data.notifyPanelMembers) {
              methods.setValue('considerationsForPanelMembers', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'text-area',
              isDisabled: !data?.notifyPanelMembers || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'modal',
      element: {
        isOpen: showModal,
        type: 'confirm-action',
        heading: 'Delete panelist review',
        body: 'Are you sure you want to delete this panelist review?',
        contentLabel: 'Panelist review delete confirmation dialog',
        successMessage: 'Panelist review deleted',
        errorMessage: 'Could not delete panelist review. Please try again.',
        primaryActionLabel: 'Delete',
        requestUrl: `/api/plan/${planId}/project/${applicationId}/panelist-review/${rowId}`,
        postActionFunc: postDeleteAction,
        onRequestClose: () => setShowModal(false),
      },
    },
    {
      type: 'field',
      element: {
        type: 'table',
        currentUserPermissions: user?.permissions,
        columnHeaders: [
          {
            elementKey: 'id',
            columnHeading: 'Id',
            viewColumn: 'id',
            type: 'hidden',
          },
          {
            elementKey: 'panelist',
            columnHeading: 'Panelist name',
            viewColumn: 'panelist',
            sortable: true,
            type: 'text',
          },
          {
            elementKey: 'dateReviewed',
            columnHeading: 'Date of review',
            viewColumn: 'dateReviewed',
            sortable: true,
            type: 'text',
          },
          {
            elementKey: 'actions',
            columnHeading: '',
            viewColumn: 'actions',
            type: 'actions-no-data',
            actions: getTableActions(data?.meetsPanelReviewThreshold),
          },
        ],
        refreshTable: refreshTable,
        tableActionHandlers: {
          deleteAction,
        },
        defaultSortBy: {
          orderColumn: 'id',
        },
        requestUrl: `/api/plan/${planId}/project/${applicationId}/panelist-review/`,
        rowClickAction: onTableRowClick,
        noResultsMessage: 'No panel feedback added',
        watch: ({ info, data, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'meetsPanelReviewThreshold') {
            const config = initialConfig as TableProps<ApplicationReviewFields>
            const columnHeaders = [...config.columnHeaders]
            if (!data.meetsPanelReviewThreshold) {
              columnHeaders.pop()
            } else {
              columnHeaders.push({
                elementKey: 'actions',
                columnHeading: '',
                viewColumn: 'actions',
                type: 'actions-no-data',
                actions: getTableActions(data.meetsPanelReviewThreshold),
              })
            }
            return {
              ...config,
              type: 'table',
              columnHeaders,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'action',
      element: {
        fieldId: 'newFeedbackBtn',
        buttonStyle: 'primary',
        type: 'button',
        icon: 'add',
        iconAlignment: 'left',
        label: 'New feedback',
        isDisabled: !hasPanelFeedbackPermission || !data?.meetsPanelReviewThreshold,
        className: 'c-btn--form-field',
        onClick: () => {
          createFeedback.mutate(
            {
              plan: planId,
              application: applicationId,
              meetsPanelReviewThreshold: meetsPanelReviewThreshold.current,
            },
            {
              onSuccess: ({ data }) => {
                navigate(`/plans/${mode}/${planId}/review/project/${applicationId}/review/panelist-review/${data.id}`)
              },
            },
          )
        },
        watch: ({ info, data, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'meetsPanelReviewThreshold') {
            return {
              ...(initialConfig as ButtonProps<ApplicationReviewFields>),
              type: 'button',
              isDisabled: !hasPanelFeedbackPermission || !data.meetsPanelReviewThreshold,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'panelChangesRequested',
        type: 'boolean',
        isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
        isDisabled: !data?.meetsPanelReviewThreshold || disableField,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (info.type === 'change' && info.name === 'meetsPanelReviewThreshold') {
            if (!data.meetsPanelReviewThreshold) {
              methods.setValue('panelChangesRequested', null)
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'boolean',
              isRequired: !!data?.meetsPanelReviewThreshold && !disableField,
              isDisabled: !data?.meetsPanelReviewThreshold || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'summaryOfPanelRequestedChanges',
        type: 'text-area',
        isRequired: !!data?.panelChangesRequested && !disableField,
        isDisabled: !data?.panelChangesRequested || disableField,
        helpText: 'Please capture notes on any discussions or changes made by the Field Advisor',
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationReviewFields>) => {
          if (
            info.type === 'change' &&
            (info.name === 'panelChangesRequested' || info.name === 'meetsPanelReviewThreshold')
          ) {
            // Check `panelChangesRequested` value if either `panelChangesRequested` value itself changes or `meetsPanelReviewThreshold` value changes
            if (!data.panelChangesRequested) {
              methods.setValue('summaryOfPanelRequestedChanges', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationReviewFields>),
              type: 'text-area',
              isRequired: !!data?.panelChangesRequested && !disableField,
              isDisabled: !data?.panelChangesRequested || disableField,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.APPROVAL,
      },
    },
  ]
}
